.HomePage {}

.HomePage-header {
  padding: 35px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.HomePage-logo {
  max-width: 50%;
  flex: 0;
}

.HomePage-logo img {
  max-width: 100%;
}

h2.HomePage-title {
  margin-top: 16px;
  margin-bottom: 0;
  text-align: center;
}
