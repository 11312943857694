.SurveyPage-header {
  text-align: center;
  padding: 20px;
  margin-bottom: 30px;
}

.SurveyPage-result {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0;
}

.SurveyPage-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 50%;
}

.SurveyPage-team {
  font-size: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.SurveyPage-flag {
  width: 50px;
  margin-bottom: 5px;
}

.SurveyPage-score {
  width: 173px;
  min-width: 90px;
  margin: 0 10px;
  font-size: 35px;
  text-align: center;
  font-weight: 600;
}

.SurveyPage-description {
  font-size: 14px;
  line-height: 22px;
}
