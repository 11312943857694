.Survey {}

.Survey-list {
  list-style: none;
  padding: 0 16px;
  margin: 0;
}

.Survey-question {
  margin-bottom: 35px;
}

.Question-title {
  margin-bottom: 15px;
  line-height: 1.4;
}

.Question-slider {
  position: relative;
  width: 45%;
}

.Slider {
  width: 100%;
}

.Slider-data {
  display: flex;
  justify-content: space-between;
  margin-top: -4px;
  padding: 0 5px;
  font-size: 14px;
}

.Slider-tooltip {
  position: absolute;
  top: -14px;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 14px;
}

.Slider-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.Question-radio {
  display: block;
  margin-bottom: 7px;
}

.Question-radio:last-child {
  margin-bottom: 0;
}

.Question-label {
  padding-left: 5px;
}

.Question-segment {
  width: 100%;
  border-color: #000000;
}

.Question-star {
  display: flex;
  justify-content: center;
}

.Star-button {
  background: none;
  border: none;
  margin: 0 20px 0 0;
  padding: 0;
}

.Star-button:focus {
  outline: none;
}

.Star-button:last-child {
  margin: 0;
}

.Question-quality {
  display: flex;
  justify-content: center;
}

.Quality-button {
  min-width: 50px;
  margin-left: 10px;
  padding: 0;
  background: none;
  border: none;
  opacity: 0.5;
}

.Quality-button_selected {
  opacity: 1;
}

.Quality-button:first-child {
  margin-left: 0;
}

.Quality-button:focus {
  outline: none;
}

.Quality-label {
  height: 10px;
  margin-bottom: 3px;
}

.Quality-label_selected {
}
