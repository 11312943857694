.SurveysList {
  margin: 0;
  border-radius: 15px
}

.SurveysList-message {
  margin: 0;
  padding: 20px;
  text-align: center;
  font-size: 18px;
}

.SurveysList-divider {
  height: 1px;
  margin-bottom: 20px;
  background: #CBCBCB;
}
