.SurveyListItem {
  padding: 0;
}
.SurveyListItem > * {
  padding-top: 0;
  padding-bottom: 6px;
  width: 100%;
}

.SurveyListItem-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
}

.SurveyListItem-header {
  font-size: 12px;
}

.SurveyListItem-match {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 32px;
  width: 100%;
  box-sizing: border-box;
}

.SurveyListItem-team {
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 0;
}

.SurveyListItem-team1 .SurveyListItem-teamPicture {
  margin-left: 8px;
}

.SurveyListItem-team2 .SurveyListItem-teamPicture {
  margin-right: 8px;
}

.SurveyListItem-teamName {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.SurveyListItem-teamPicture {
  width: 24px;
}


.SurveyListItem-team:first-child {
  text-align: right;
}

.SurveyListItem-score {
  width: 58px;
  margin: 0 10px;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
}

.SurveyListItem-icon_completed {
  position: absolute;
  left: 8px;
  top: 10px;
}
