.PageWrapper {
  max-width: 500px;
  margin: auto;
  position: relative;
  padding-bottom: 50px;
}

.Placeholder-container {
  padding-top: 45px;
}

.Placeholder {
  margin: auto;
  border-radius: 5px;
  background: #e3e3ec;
  background: linear-gradient(to right, #e3e3ec 8%, #d7d7de 18%, #e3e3ec 33%);
  background-size: 800px 104px;
  animation-name: placeHolderShimmer;
  animation-duration: 2.0s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.Placeholder-logo {
  max-width: 50%;
  height: 150px;
  margin-bottom: 15px;
}

.Placeholder-header {
  height: 30px;
  width: 50%;
  margin-bottom: 15px;
}

.Placeholder-title {
  height: 30px;
  width: 60%;
  margin-bottom: 45px;
}

.Placeholder-item {
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
}

.Placeholder-date {
  width: 30%;
  height: 30px;
  margin-bottom: 20px;
}

.Placeholder-result {
  width: 70%;
  height: 60px;
  margin-bottom: 20px;
}

.Placeholder-name {
  height: 30px;
  width: 60%;
  margin-bottom: 55px;
}

.Question-group {
  margin-bottom: 30px;
  padding-left: 35px;
}

.Placeholder-question {
  margin: 0 0 15px 0;
  width: 70%;
  height: 20px;
}

.Placeholder-option {
  margin: 0 0 10px 0;
  width: 50%;
  height: 20px;
}
