.App {
  position: relative;
  height: 100%;
  padding-bottom: 50px;
  box-sizing: border-box;
}

.App-footer {
  width: 100%;
  text-align: center;
  font-size: 12px;
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  z-index: 2;
  background: #EFEFF4;
}

.router {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slide-enter,
.slide-exit {
  transition: transform 250ms ease-out;
}

.slide-enter {
  transform: translateX(100%);
}

.slide-enter.slide-enter-active {
  transform: translateX(0%);
}

.slide-exit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateX(0%);
}

.slide-exit-active {
  transform: translateX(-100%);
}
