.LoadingPage {
  max-width: 500px;
  margin: auto;
}

.LoadingPage-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.LoadingPage-title {
  font-size: 40px !important;
}

.circle-loader {
  margin-bottom: 6em;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-left-color: #439B22;
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: 12em;
  height: 12em;
}

.load-complete {
  -webkit-animation: none;
  animation: none;
  border-color: #439B22;
  transition: border 500ms ease-out;
}

.checkmark {
  display: none;
}

.checkmark-show {
  display: block;
}

.draw:after {
  animation-duration: 800ms;
  animation-timing-function: ease;
  animation-name: checkmark;
  transform: scaleX(-1) rotate(135deg);
}

.checkmark:after {
  opacity: 1;
  height: 6em;
  width: 3em;
  transform-origin: left top;
  border-right: 3px solid #439B22;
  border-top: 3px solid #439B22;
  content: '';
  left: 3em;
  top: 6em;
  position: absolute;
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 3em;
    opacity: 1;
  }
  40% {
    height: 6em;
    width: 3em;
    opacity: 1;
  }
  100% {
    height: 6em;
    width: 3em;
    opacity: 1;
  }
}

.LoadingPage-error {
  margin-bottom: 46px;
  border: 1px solid red;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 12em;
  height: 12em;
}

.LoadingPage-error span {
  color: red;
  font-size: 84px;
}

.LoadingPage-title {
  font-size: 40px !important;
  color: #626262;
}
